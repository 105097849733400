import React from "react";
import { Form } from "@themesberg/react-bootstrap";
import { ErrorMessage, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";

export const FormikReactSelect = ({ name, label, variant, className, options = [{ label: "Option 1", value: "null" }], type, ...props }) => {
  const { values, setFieldValue } = useFormikContext();

  return (<>
    <Form.Label htmlFor={name} style={{ fontWeight: 500 }} data-variant={variant}>{label}</Form.Label>
    <CreatableSelect
      name={name}
      classNamePrefix="select"
      options={options}
      value={options.find(({ value }) => value == values[name]) || null}
      onChange={(option, e) => {
        setFieldValue(name, option?.value);
      }}
      className={classNames(variant ? "select-dark" : "", className)}
      {...props}
    />
    <ErrorMessage
      component="small"
      name={name}
      className="text-danger"
    />
  </>)
}
import { STATUS_LABEL } from "../../../utils/constant"

export const kycLabels = [
  { value: '', label: 'Kyc Status' },
  { value: 'APPROVED', label: STATUS_LABEL.APPROVED },
  { value: 'PENDING', label: STATUS_LABEL.PENDING },
  { value: 'REJECTED', label: STATUS_LABEL.REJECTED },
  { value: 'REQUESTED', label: STATUS_LABEL.REQUESTED },
  { value: 'RE_REQUESTED', label: STATUS_LABEL.RE_REQUESTED }
]

export const tableHeaders = [
  { label: 'Id', value: 'userId' },
  { label: 'Usuário', value: 'username' },
  { label: 'E-mail', value: 'email' },
  { label: 'Telefone', value: 'phoneNumber' },
  { label: 'Status', value: 'status' },
  { label: 'kyc status', value: 'kycStatus' },
  { label: 'Affiliado', value: 'affiliateId' },
  { label: 'Interno', value: 'is internal' },
  { label: 'Cadastrado em:', value: 'createdAt' },
  { label: 'action', value: 'action' }
]

import React, { useState } from "react";
import { Form } from "@themesberg/react-bootstrap";
import { ErrorMessage, useFormikContext } from "formik";
import InputMask from 'react-input-mask';
import Button from "../Button";

import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
import UploadImageInput from "../UploadImageInput";

const FaEye = () => <FontAwesomeIcon icon={faEye} className="icon-size-input-group" />
const FaEyeSlash = () => <FontAwesomeIcon icon={faEyeSlash} className="icon-size-input-group" />

export const FormikInput = ({ name, label, mask, type, variant, ...props }) => {
  const { values, handleChange } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);

  return (<>
    <Form.Label htmlFor={name} style={{ fontWeight: 500 }} data-variant={variant}>{label}</Form.Label>
    <InputGroup data-variant={variant}>
      <Form.Control
        name={name}
        type={showPassword ? "text" : type}
        value={values[name] || ""}
        onChange={handleChange}
        {...(mask ? {
          as: InputMask,
          maskChar: false,
          mask,
        } : {})}
        {...props}
        data-variant={variant}
      />
      {type == "password" ? <Button variant="input-group" className="text-muted" onClick={() => setShowPassword(!showPassword)}>{!showPassword ? <FaEye /> : <FaEyeSlash />}</Button> : null}
    </InputGroup>
    <ErrorMessage
      component="small"
      name={name}
      className="text-danger"
    />
  </>)
}

export const FormikUploadImageBase64 = ({ name, label, type, variant, ...props }) => {
  const { values, setFieldValue } = useFormikContext();

  return (<>
    <Form.Label htmlFor={name} style={{ fontWeight: 500 }} data-variant={variant}>{label}</Form.Label>
    <UploadImageInput
      name={name}
      src={values[name]}
      onBase64Change={(base64) => {
        setFieldValue(name, base64)
      }}
      variant={variant}
      {...props}
    />
    <ErrorMessage
      component="small"
      name={name}
      className="text-danger"
    />
  </>)
}
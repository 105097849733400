import "./style.scss";
import React from "react";
import { Formik, Form } from "formik";
import { Col, Row } from "@themesberg/react-bootstrap";

import signinPageBackground from "../../../assets/img/signin-page-background.png";
import onlybetsLogo from "../../../assets/img/logo.png";

import Button from "../../../components/Button";
import { affiliateSignupInitialValues, affiliateSignupSchema, affiliateTypeOptions } from "./schema";
import { FormikInput, FormikUploadImageBase64 } from "../../../components/utils/FormikInput";
import { FormikReactSelect } from "../../../components/utils/FormikSelect";
import { useAffiliateSignup } from "./useAffiliateSignup";

export const AffiliateSignupPage = () => {
  const { loading, onSubmit } = useAffiliateSignup();

  return (
    <main className="d-flex bg-black signin-page-wrapper flex-sm-row flex-column-reverse">
      <Col sm={6} xs={12} className="col-left-side p-5 px-sm-5 px-3">
        <Col xs={12} className="d-flex align-items-center justify-content-center">
          <Formik
            initialValues={affiliateSignupInitialValues()}
            validationSchema={affiliateSignupSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, errors, values }) => {
              // console.log(values)
              return (
                <Form style={{ maxWidth: "30rem" }} className="w-100">
                  <h1 className="mb-4">Cadastro de Afiliado</h1>
                  <Row>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikInput name={"firstName"} label={"Nome"} variant={"dark"} />
                    </Col>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikInput name={"lastName"} label={"Sobrenome"} variant={"dark"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikInput name={"username"} label={"Nome de usuário"} variant={"dark"} />
                    </Col>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikInput name={"phone"} label={"Telefone"} mask="(99) 99999-9999" variant={"dark"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikReactSelect name={"affiliateType"} label={"Tipo de afiliado"} options={affiliateTypeOptions} variant={"dark"} />
                    </Col>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikInput name={"cpf"} label={"CPF"} mask="999.999.999-99" variant={"dark"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12} className="mb-3">
                      <FormikInput name={"email"} label={"E-mail"} variant={"dark"} />
                    </Col>
                    {/* <Col sm={6} xs={12} className="mb-3">
                      <Col md={12} xs={12} className="mb-3">
                        <FormikInput name={"password"} label={"Senha"} type={"password"} />
                      </Col>
                      <Col md={12} xs={12} className="mb-3">
                        <FormikInput name={"confirmPassword"} label="Confirmar Senha" type={"password"} />
                      </Col>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col md={6} xs={12} className="mb-3">
                      <FormikUploadImageBase64 name={"avatar"} label={"Imagem de Perfil"} variant={"dark"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button className="w-100" type="submit" /* onClick={handleSubmit} */ loading={loading}>Enviar</Button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Col>
        <Col xs={12} className="d-flex justify-content-center mt-5">
          <p className="m-0 text-muted text-center">© {new Date().getFullYear()} Betpah. Todos Direitos Reservados.</p>
        </Col>
      </Col>
      <Col sm={6} xs={12} className="col-right-side">
        <img src={signinPageBackground} className="w-100 h-100" alt="" />
        <img src={onlybetsLogo} className="onlybets-logo mb-4" alt="" />
      </Col>
    </main>
  );
};

export default AffiliateSignupPage;
import React from 'react'
import { Typography } from '@mui/material'
import { Row, Col, Container, Button } from '@themesberg/react-bootstrap'
import useLivePlayers from '../../hooks/useLivePlayers'
import { SpinnerLoader } from '../../../../../components/Preloader'
import './livePlayer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faMobileAlt, faTabletAlt, faRedo } from '@fortawesome/free-solid-svg-icons'
import { CircleChart } from '../../../../../components/CircleChart'
import { getTextColor } from '../../../../../utils/dashboardEffects'
import DashboardIconBox from '../../StateBox'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ContactsIcon from '@mui/icons-material/Contacts'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AddCardIcon from '@mui/icons-material/AddCard'
import { DashboardHeadTitle, DashboardLiveContainer } from '../../style'
import Highcharts from 'highcharts'
import PieChart from 'highcharts-react-official'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { defaultCurrency } from '../../../../../utils/constant'
import FormatNumber from '../../../../../utils/formatNumber';
import FormatDate from '../../../../../utils/formatDate'
import UpdateButtonIcon from '../../../../../components/UpdateButtonIcon'
import DateRangePicker from '../../../../../components/DateRangePicker'
import Trigger from '../../../../../components/OverlayTrigger'


const dataToCChange = (data) => {
  const options = {
    chart: {
      type: 'pie',
      height: (12.5 / 16 * 100) + '%',
      backgroundColor: {
        linearGradient: [0, 0, 500, 500],
        stops: [
          [0, 'rgb(255, 255, 255)'],
          [1, 'rgb(200, 200, 255)']
        ]
      },
      polar: true,
      events: {
        // render: function () {
        // 	const title = this.title
        // 	title.attr({
        // 	y: this.plotTop + this.plotHeight / 2 +
        // 			title.getBBox().height / 2 -
        // 			parseInt(title.styles.fontSize)
        // 	})
        // }
      }
    },
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 500
        },
        chartOptions: {
          chart: {
            height: 300
          },
          subtitle: {
            text: null
          },
          navigator: {
            enabled: false
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    tooltip: {
      followPointer: true,
      hideDelay: 100,
      //pointFormat: 'Facilities: {point.y:,.1f} <br>{point.percentage:.1f}%'
      formatter: function () {
        return '<b>' + this.point.name + ': ' + this.y.toLocaleString() + ' players';
      }
    },

    title: {
      text: 'Usuários por dispositivos',
      // widthAdjust: -300,
      style: { "fontSize": "12px", fontWeight: 800 },
      align: 'right',
      padding: 10
      //   verticalAlign: 'bottom'
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      pie: {
        size: 200,
        innerSize: '70%',
        dataLabels: {
          enabled: true,
          connectorWidth: 1,
          distance: 25,
          style: {
            color: "contrast",
            fontSize: "11px",
            textOutline: "none", //"1px contrast",
          },
          formatter: function () {
            return this.point.name + ':<br/> ' + this.percentage.toFixed(1) + ' %';
          }
        },
        showInLegend: true
      },
    }
    // plotOptions: {
    //   pie: {
    //     size: 100
    //     // innerSize: 150,
    //     // depth: 10,
    //     // allowPointSelect: true,
    //     // cursor: 'pointer',
    //     // dataLabels: {
    //     //   enabled: true,
    //     //   format: '<b>{point.name}</b>: {point.percentage:.1f} %',
    //     //   connectorColor: 'silver'
    //     // }
    //   }
    // }
  }

  const filterData = data?.filter(d => d.device_type !== null).map(d => {
    return { name: d.device_type, y: Number(d.count), count: Number(d.count) }
  })
  options.series = [
    {
      name: 'Usuários por dispositivos',
      data: filterData,
      showInLegend: true
    }
  ]
  return options
}
const dataAtual = new Date();

export default ({ selectedPortal, selectedClient }) => {
  const {
    loading,
    livePlayerData,
    toDay,
    dateRange,
    setDateRange,
  } = useLivePlayers({ selectedPortal, selectedClient })

  const icons = { desktop: faDesktop, mobile: faMobileAlt, tablet: faTabletAlt }
  const color = { desktop: 'secondary', mobile: 'primary', tablet: 'tertiary' }

  return (livePlayerData &&
    <DashboardLiveContainer>
      <DashboardHeadTitle as={Row}>
        <Col xs={12} lg={6} className='d-flex align-items-center'>
          <p className="mb-0">Selecione um intervalo de datas para buscar</p>
          <UpdateButtonIcon
            className={"ms-2"}
            onClick={() => toDay()}
            {...{ loading }}
          />
        </Col>
        <Col xs={12} lg={6} className='mt-lg-0 mt-2'>
          <Row>
            <Col xs={12} xl={6} lg={12} className='ms-auto d-flex align-items-center'>
              {/* <Button onClick={toDay} className='me-2' size='sm'>Hoje</Button> */}
              <DateRangePicker
                state={dateRange}
                setState={setDateRange}
                size='sm'
                placement={"right"}
              />
            </Col>
          </Row>
        </Col>
      </DashboardHeadTitle>
      <Row className="align-items-center justify-content-center">

        <Col xl={12}>
          <Row>
            <Col xl={9} lg={12} md={6} className='mb-4'>
              <div className="chart-container">
                {/* <Typography variant='h6' className='chart-container-text'>Logged In Players</Typography> */}
                <PieChart
                  highcharts={Highcharts}
                  options={dataToCChange(livePlayerData?.deviceLoggedIn)}
                />
              </div>
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                bgColor="linear-gradient(100.66deg, rgb(67, 67, 67) 6.56%, rgb(0, 0, 0) 93.57%)"
                icon={<CurrencyExchangeIcon />}
                iconColor="rgb(30 30 30)"
                title="GGR"
                count={`${FormatNumber.currency(Number(livePlayerData.todayTotalGgr) || 0)}`}
              //footerText='compared to previous month'
              />
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Total de Usuários"
                count={livePlayerData.totalPlayers}
                bgColor="linear-gradient(-20deg, rgb(43, 88, 118) 0%, rgb(78, 67, 118) 100%)"
                icon={<PersonAddIcon />}
                iconColor="rgb(54 67 119)"
              // footerText='decrease in orders amounts'
              />
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Taxa de conversão de registro"
                count={`${livePlayerData.registrationConvRate || 0} %`}
                bgColor="linear-gradient(rgb(0, 176, 155), rgb(150, 201, 61))"
                icon={<ContactsIcon />}
                iconColor="rgb(102 182 135)"
              // footerText='compared to previous month'
              />
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Taxa de conversão de depósitos"
                count={`${livePlayerData.depositConvRate || 0} %`}
                bgColor="linear-gradient(135deg, #ffe661 0%, #ff7557 100%)"
                icon={<AddCardIcon />}
                iconColor="#ff7557"
              //  footerText='decrease in orders amounts'
              />
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Quantidade de depósitos"
                count={`${livePlayerData.depositCountPlayers || 0}`}
                bgColor="linear-gradient(135deg, #ffc6c6 0%, #ffb54d 100%)"
                icon={<PeopleAltIcon />}
                iconColor="#ffb54d"
              //  footerText='decrease in orders amounts'
              />
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Valor total depositado"
                count={FormatNumber.currency(livePlayerData.totalDepositedAmount || 0)}
                bgColor="linear-gradient(135deg, #6b7ece 0%, #796f1f 100%)"
                icon={<AccountBalanceIcon />}
                iconColor="#796f1f"
              //  footerText='decrease in orders amounts'
              />
            </Col>
            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Total de Saques"
                count={livePlayerData.withdrawCountRequestPlayers || 0}
                bgColor="linear-gradient(135deg, #6b7ece 0%, #796f1f 100%)"
                icon={<PeopleAltIcon />}
                iconColor="#796f1f"
              //  footerText='decrease in orders amounts'
              />
            </Col>

            <Col xl={3} lg={4} md={6} className='pb-4'>
              <DashboardIconBox
                title="Total de pagamentos"
                count={FormatNumber.currency(livePlayerData.totalWithdrawRequestAmount || 0)}
                bgColor="linear-gradient(135deg, rgb(171, 220, 255) 0%, rgb(140, 50, 150) 100%);"
                icon={<AccountBalanceIcon />}
                iconColor="rgb(87 177 245)"
              //  footerText='decrease in orders amounts'
              />
            </Col>
            <Trigger placement='top' message={"Soma do saldo da carteira de todos os usuários (Hoje)"}>
              <Col xl={3} lg={4} md={6} className='pb-4'>
                <DashboardIconBox
                  title="Total da carteira dos usuários"
                  count={FormatNumber.currency(livePlayerData.totalWalletUsers || 0)}
                  bgColor="linear-gradient(135deg, rgb(54 111 153) 0%, rgb(16 113 136) 100%);"
                  icon={<AccountBalanceIcon />}
                  iconColor="rgb(16 113 136)"
                //  footerText='decrease in orders amounts'
                />
              </Col>
            </Trigger>
          </Row>
        </Col>
      </Row>

      {/* <Row className='d-none'>
        <Col lg={3}>
          <DashboardIconBox
            title='TODAY GGR'
            count={`€ ${livePlayerData.todayTotalGgr || 0}`}
            bgColor='linear-gradient(100.66deg, rgb(67, 67, 67) 6.56%, rgb(0, 0, 0) 93.57%)'
            icon={<CurrencyExchangeIcon />}
            iconColor='rgb(30 30 30)'
            footerText='compared to previous month'
          />
        </Col>
        <Col lg={3}>
          <DashboardIconBox
            title='TOTAL PLAYERS'
            count={livePlayerData.totalPlayers}
            bgColor='linear-gradient(-20deg, rgb(43, 88, 118) 0%, rgb(78, 67, 118) 100%)'
            icon={<PersonAddIcon />}
            iconColor='rgb(54 67 119)'
            footerText='decrease in orders amounts'
          />
        </Col>
        <Col lg={3}>
          <DashboardIconBox
            title='REGISTRATION CONV. RATE'
            count={`${livePlayerData.registrationConvRate || 0} %`}
            bgColor='linear-gradient(rgb(0, 176, 155), rgb(150, 201, 61))'
            icon={<ContactsIcon />}
            iconColor='rgb(102 182 135)'
            footerText='compared to previous month'
          />
        </Col>
        <Col lg={3}>
          <DashboardIconBox
            title='DEPOSIT CONV. RATE'
            count={`${livePlayerData.depositConvRate || 0} %`}
            bgColor='linear-gradient(135deg, rgb(171, 220, 255) 0%, rgb(3, 150, 255) 100%)'
            icon={<AddCardIcon />}
            iconColor='rgb(87 177 255)'
            footerText='decrease in orders amounts'
          />
        </Col> */}
      {/* </Row>
			</Card>
		</Col> */}
      {/* <Col style={{ maxWidth: '300px' }}>
				<Card className='p-2 mb-2 graph-container'>
				<Row className='d-block d-xl-flex align-items-center'>
					<Col xs={12} xl={5} className='px-xl-0 text-center'>
					<h3 className='green-text'>{livePlayerData?.loggedInPlayer || 0} </h3>
					<p className='gray-text'>Logged In Players</p>
					<CircleChart series={livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => d.count) || []} />
					</Col>
					<Col xs={12} xl={7} className='px-xl-0 text-center'>

					{livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => (
						<p key={`circle-element-${d.device_type}`} className='text-gray device-report'>
						<FontAwesomeIcon icon={icons[d.device_type]} className={`icon icon-xs text-${color[d.device_type]} w-20 me-1`} />
						{` ${d.device_type} `}{`${d.count}`}
						</p>
					))}
					</Col>
				</Row>
				</Card>
			</Col>
			</Row> */}
      {/* </Row> */}
    </DashboardLiveContainer>

  )
}

import React, { useState } from 'react'
import { Col, Form, InputGroup, Modal, Row, Table, Form as BForm, Spinner } from '@themesberg/react-bootstrap'
import { useSelector } from 'react-redux'
import './modalStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import Trigger from '../OverlayTrigger'
import { toast } from '../Toast'
import PaginationComponent from '../Pagination'
import { formatDateYMD } from '../../utils/dateFormatter'
import CopyToClipboard from 'react-copy-to-clipboard'
// import { kycMethods } from './constants'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ErrorMessage, Formik } from 'formik'
import { passwordValidation } from './schema'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup'
import { AdminsRoutes } from '../../routes'
import Button from '../Button'
import { AFFILIATE_TYPE_LABEL_BY_VALUE } from '../../utils/constant'
import useRole from '../../hooks/useRole'
const { REACT_APP_AWS_S3_BASE_URL, REACT_APP_S3_BUCKET_NAME } = process.env

export const UpdateKycMethodModal = ({ show, setShow, countryData, setCountryData, handleKycUpdateMethod, languages }) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header className='justify-content-center'>
      <h5>Update Country Data</h5>
    </Modal.Header>
    <Modal.Body>
      <Table borderless>
        <tbody>
          <tr>
            <td>
              <b>Country Name</b>
            </td>
            <td>
              {countryData?.countryName}
            </td>
          </tr>
          {/* <tr>
            <td>
              <b>Kyc Method</b>
            </td>
            <td>
              <Form.Select
                size='sm'
                className='ml-2'
                style={{ maxWidth: '230px' }}
                value={countryData?.kycMethod}
                onChange={(e) => {
                  setCountryData((prev) => ({ ...prev, kycMethod: e.target.value }))
                }}
              >
                {kycMethods?.map(
                  ({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  )
                )}
              </Form.Select>
            </td>
          </tr> */}

          <tr>
            <td>
              <b>Language</b>
            </td>
            <td>
              <Form.Select
                size='sm'
                className='ml-2'
                style={{ maxWidth: '230px' }}
                value={countryData?.languageId}
                onChange={(e) => {
                  setCountryData((prev) => ({ ...prev, languageId: e.target.value }))
                }}
              >
                {languages?.count > 0 &&
                  languages?.rows?.map(
                    ({ languageId, languageName }) => (
                      <option key={languageId} value={languageId}>
                        {languageName}
                      </option>
                    )
                  )}
              </Form.Select>
            </td>
          </tr>
        </tbody>
      </Table>
    </Modal.Body>

    <Modal.Footer className='justify-content-between'>
      <Button className='btn-danger' onClick={() => { setShow(false) }}>
        Cancel
      </Button>

      <Button
        className='btn-primary' onClick={() => {
          handleKycUpdateMethod({ countryData })
        }}
      >
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
)

export default ({ show, setShow, handleYes, active, name }) => {
  return (

    <Modal show={show} onHide={() => setShow(false)}>

      <Modal.Body>
        <div className='fs-5'>
          Are you sure you want to mark {name || ''} {active === false ? 'Active' : 'In-Active'}?
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className='btn-primary' onClick={handleYes}>
          Yes
        </Button>

        <Button className='btn-danger' onClick={() => setShow(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const DeleteConfirmationModal = ({
  deleteModalShow,
  setDeleteModalShow,
  handleDeleteYes,
  name
}) => (
  <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Are you sure you want to delete {name || ''}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleDeleteYes}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setDeleteModalShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const ApproveRejectModal = ({
  show,
  setShow,
  handleYes,
  status,
  name
}) => (
  <Modal show={show} onHide={() => setShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Tem certeza que deseja {status === 'approved' ? 'Aprovar ' : 'Cancelar '} {name}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button
        variant='outline-secondary' onClick={() => {
          if (status === 'approved') { handleYes('', '') } else { handleYes('', 'cancel') }
        }}
      >
        Confirmar
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        Cancelar
      </Button>
    </Modal.Footer>
  </Modal>
)

export const GalleryModal = ({
  galleryModal,
  setGalleryModal
}) => {
  const { gallery } = useSelector(state => state.emailTemplate)

  return (
    <Modal show={galleryModal} onHide={() => setGalleryModal(false)} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Gallery</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className='text-center align-items-center'>
          {gallery?.length > 0
            ? gallery?.map((img, index) => {
              return (
                <Col key={index} md={3} className='imagecontainer'>
                  <CopyToClipboard
                    text={img?.fileName}
                    onCopy={() => {
                      setGalleryModal(false)
                      toast('Copied To ClipBoard', 'success')
                    }}
                  >
                    <img
                      src={img?.fileName}
                      width='200'
                      height='150'
                      alt='gallery-img'
                      style={{ border: '2px solid aliceblue', borderRadius: '12px', cursor: 'pointer' }}
                      className='mb-2'
                    />
                  </CopyToClipboard>
                  <div className='text'>{img?.name}</div>
                  <Trigger message='Copy This Image URL to ClipBoard'>
                    <CopyToClipboard
                      text={img?.fileName}
                      onCopy={() => {
                        setGalleryModal(false)
                        toast('Copied To ClipBoard', 'success')
                      }}
                    >
                      <Button
                        className='copy d-flex align-items-center'
                        variant='light'
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </CopyToClipboard>
                  </Trigger>
                </Col>
              )
            })
            : <h4 className='text-danger'>No Image Found</h4>}
        </Row>
      </Modal.Body>

    </Modal>
  )
}

export const DuplicateUserModal = ({
  show,
  setShow,
  limit,
  pageNo,
  setLimit,
  setPageNo
}) => {
  const { duplicateUsers } = useSelector(state => state.tenantUsers)
  const { userData: user } = useSelector(state => state.fetch)

  const totalPages = Math.ceil(duplicateUsers?.count / limit)
  const tableHeaders = [
    { label: 'User Id', value: user?.userId || 'NA' },
    { label: 'First Name', value: user?.firstName || 'NA' },
    { label: 'Last Name', value: user?.lastName || 'NA' },
    { label: 'Email', value: user?.email || 'NA' },
    { label: 'User Name', value: user?.username || 'NA' },
    { label: 'Phone', value: user?.phone || 'NA' },
    { label: 'SignIn IP', value: user?.signInIp || 'NA' },
    { label: 'DOB', value: user?.dateOfBirth ? formatDateYMD(user?.dateOfBirth) : 'NA' },
    { label: 'Address', value: user?.address || 'NA' }
  ]

  return (
    <Modal show={show} onHide={() => setShow(false)} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-2'>Duplicates</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <div style={{ overflow: 'auto', maxHeight: '640px' }}>
          <Table

            size='sm'
            className='text-center fixTableHead'
          >
            <thead>
              <tr className='thead-light fixTableHead-th'>
                {tableHeaders.map((h, idx) => (
                  <th key={`T-table_heading ${idx}`}>
                    <span>{h.label}</span><br />
                    <small>({h.value})</small>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {duplicateUsers &&
                duplicateUsers?.rows?.map(
                  ({
                    firstName,
                    lastName,
                    email,
                    username,
                    phone,
                    signInIp,
                    dateOfBirth,
                    address,
                    userId
                  }) => {
                    return (
                      <tr key={`duplicate-list ${userId}`}>
                        <td>{userId}</td>
                        <td>{firstName || 'NA'}</td>
                        <td>{lastName || 'NA'}</td>
                        <td>{email || 'NA'}</td>
                        <td>{username || 'NA'}</td>
                        <td>{phone || 'NA'}</td>
                        <td>{signInIp || 'NA'}</td>
                        <td>{dateOfBirth ? formatDateYMD(dateOfBirth) : 'NA'}</td>
                        <td>{address || 'NA'}</td>
                      </tr>
                    )
                  }
                )}
              {duplicateUsers?.count === 0 && (
                <tr>
                  <td colSpan={9} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {duplicateUsers?.count > 0 && (
          <PaginationComponent
            page={duplicateUsers?.count < pageNo ? setPageNo(1) : pageNo}
            totalPages={totalPages}
            setPage={setPageNo}
            limit={limit}
            setLimit={setLimit}
          />
        )}

      </Modal.Body>
    </Modal>
  )
}

export const ResetConfirmationModal = ({
  show,
  setShow,
  handleYes,
  data
}) => (
  <Modal show={show} onHide={() => setShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Are you sure you want to reset {data || ''}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => handleYes(data)}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const ChangeLangConfirmationModal = ({
  show,
  setShow,
  handleYes
}) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Are you sure?</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <h6>Switching Language would discard all the changes that you made.</h6>
      <h6>Are you sure you want to switch to other language?</h6>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => handleYes()}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const VerifyEmailModal = ({
  show,
  setShow,
  userData,
  handleVerifyEmail
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Mark '{userData?.firstName} {userData?.lastName} ({userData?.email})' as Verified
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>Do you really want to mark <strong>{userData?.firstName} {userData?.lastName} ({userData?.email})</strong> as Verified?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleVerifyEmail}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ResetPasswordEmailModal = ({
  show,
  setShow,
  userData,
  handleResetPasswordEmail
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Send Password Reset Email to '{userData?.firstName} {userData?.lastName} ({userData?.email})'
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to send  a password reset email to <strong>{userData?.firstName} {userData?.lastName} ({userData?.email})</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleResetPasswordEmail}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ResetUserPasswordModal = ({
  show,
  setShow,
  userData,
  handleResetUserPassword
}) => {
  const [type, setType] = useState('password')

  return (
    <Formik
      initialValues={{
        password: ''
      }}
      validationSchema={passwordValidation}
      onSubmit={(formValues) => {
        handleResetUserPassword({
          data: {
            password: Buffer.from(formValues?.password).toString('base64'),
            userId: userData?.userId
          }
        })
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form className='align-items-center mt-3'>
          <Modal show={show}>
            <Modal.Header>
              <h4>Resetar senha para {userData?.username}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Label>
                    Nova Senha <span className='text-danger'> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name='password'
                      type={type}
                      placeholder='Enter Password'
                      value={values?.password}
                      onChange={handleChange}
                    />
                    <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                      {type === 'password'
                        ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => { setType('text') }} />
                        : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => { setType('password') }} />}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component='div'
                    name='password'
                    className='text-danger'
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setShow(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                className='m-2'
                variant='outline-success'
                style={{ width: '75px' }}
                onClick={handleSubmit}
              >
                Alterar
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>)}
    </Formik>
  )
}

export const ApproveAffiliateModal = ({
  show,
  setShow,
  affiliateByIdData,
  handleApproveAffiliate,
  affiliateApproveLoading
}) => {
  const { AdminOrSuperAdmin, Affiliate } = useRole();

  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Aprovar '{affiliateByIdData?.username}' como {AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateByIdData?.affiliateType]}?
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>Você realmente quer aprovar <strong>{affiliateByIdData?.username}</strong> como um {AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateByIdData?.affiliateType]}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleApproveAffiliate}
          loading={affiliateApproveLoading}
        >
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const RejectAffiliateModal = ({
  show,
  setShow,
  affiliateByIdData,
  handleRejectAffiliate,
  affiliateRejectLoading
}) => {

  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Rejeitar '{affiliateByIdData?.username}' como {affiliateByIdData?.affiliateType ? AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateByIdData?.affiliateType] : "Afiliado"}?
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>Você realmente quer rejeitar <strong>{affiliateByIdData?.username}</strong> como um {affiliateByIdData?.affiliateType ? AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateByIdData?.affiliateType] : "Afiliado"}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleRejectAffiliate}
          loading={affiliateRejectLoading}
        >
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const SendSomeLoyaltyPointsUserModal = ({ handleSendSomeLoyaltyPointsUser, show, loading, setSendLoyaltyPointsToUserModal }) => {
  const createLoyaltyRuleSchema = Yup.object().shape({
    loyaltyPoint: Yup.number().required('Loyalty Point is required').min(1, 'Must be greater than or equal to one').max(1000, 'Must be less than 10000').positive('Loyalty Point must be a positive number'),
  });
  const navigate = useNavigate()
  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Send Loyalty Points To User</h4>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{

            loyaltyPoint: 0,
          }}
          validationSchema={createLoyaltyRuleSchema}
          onSubmit={({ loyaltyPoint }) =>
            handleSendSomeLoyaltyPointsUser({
              loyaltyPoint
            })}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur
          }) => (
            <div className='m-4'>
              <Form>
                <Row>
                  <Col xs='12'>
                    <div className='form-group mb-2'>
                      <label
                        htmlFor='loyaltyPoint'
                        className={touched.loyaltyPoint && errors.loyaltyPoint ? 'text-danger' : ''}
                      >
                        <b>Loyalty Point</b>
                        <span className='text-danger'> *</span>
                      </label>

                      <InputGroup
                        className={
                          touched.loyaltyPoint && errors.loyaltyPoint ? 'border border-danger' : ''
                        }
                      >
                        <BForm.Control
                          name='loyaltyPoint'
                          required
                          type='number'
                          placeholder='Enter loyalty point'
                          value={values.loyaltyPoint}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>

                      <ErrorMessage
                        component='div'
                        name='loyaltyPoint'
                        className='text-danger'
                      />
                    </div>
                  </Col>
                </Row>

                <div className='mt-4 d-flex justify-content-between align-items-center'>
                  <Button
                    className='btn-danger ml-2'
                    onClick={() => setSendLoyaltyPointsToUserModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='btn-primary'
                    onClick={handleSubmit}
                  >
                    Submit
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  )
} 
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { addUserInternalTagtStart, getAllBonusStart } from '../../../../store/redux-slices/admins'
import { getBonusStart, issueBonusStart } from '../../../../store/redux-slices/bonus'
import { getAffiliateByIdStart, getAffiliateCommissionReportByIdStart, getAffiliateDashboardDataFromAdminStart, getCountriesStart, getSAUserDocumentStart, getUserStart, verifyUserDocumentStart } from '../../../../store/redux-slices/fetchData'
import { getDocumentLabelStart, updateDocumentStart, updateSAUserStatusStart } from '../../../../store/redux-slices/superAdminSettings'
import { addAffiliateUserStart, getDuplicateUsersStart, resetPasswordEmailStart, resetUserPasswordStart, updateTagsStart, updateUserAffiliateStart, verifyUserEmailStart } from '../../../../store/redux-slices/tenantUsers'
import { approveAffiliate, rejectAffiliate, approveSubAffiliate, getDocumentLabel } from '../../../../utils/apiCalls'
import { formatDate, formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { safeStringify } from '../../../../utils/helpers'
import { registerAffiliateStart } from '../../../../store/redux-slices/affiliates'
import { format, startOfMonth, subDays } from 'date-fns'
import { toast } from '../../../../components/Toast'
import { AFFILIATE_TYPE_LABEL_BY_VALUE } from '../../../../utils/constant'
import { AdminsRoutes, AffiliateRoutes } from '../../../../routes'
import { resolveErrors } from '../../../../utils/errorTypes'
// import { safeStringify } from '../../../utils/helpers'

const useAffiliateDetails = () => {
  const [searchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") || 'overview')
  const { userData, loading, updateUserAffiliateLoading, addUserAffiliateLoading, affiliateByIdData } = useSelector((state) => state.fetch)
  const { documentLabels } = useSelector(state => state.superAdminSettings)
  const { bonusList } = useSelector((state) => state.admins)
  const { bonusDetail } = useSelector((state) => state.bonus)
  const { userId, affiliateId } = useParams()
  const dispatch = useDispatch()
  const { userDocuments } = useSelector(state => state.fetch)
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('')
  const [userDocumentId, setUserDocumentId] = useState('')
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [enable, setEnable] = useState(false)
  const [docLabels, setDocLabels] = useState('')
  const [title, setTitle] = useState('')
  const [imagePreviewModalShow, setImagePreviewModalShow] = useState(false)
  const [imageUrl, setImageUrl] = useState({ name: '', preview: [] })
  const [showModal, setShowModal] = useState(false)
  const [freespinModal, setFreeSpinModal] = useState(false)
  const [showManageMoneyModal, setShowManageMoneyModal] = useState(false)
  const [selectedBonus, setSelectedBonus] = useState()
  const [bonusLoading, setBonusLoading] = useState(false)
  const [bonusAmount, setBonusAmount] = useState()
  const [isInternalModal, setIsInternalModal] = useState(false)
  const [name, setName] = useState('')
  const [showRemoveAffiliate, setShowRemoveAffiliate] = useState(false)
  const [showAddAffiliate, setShowAddAffiliate] = useState(false)
  const [labelName, setLabelName] = useState('')
  const [isActiveInactivemodalVisible, setActiveInactiveModal] = useState(false)
  const [verifyEmailModal, setVerifyEmailModal] = useState(false)
  const [userPasswordModal, setUserPasswordModal] = useState(false)
  const [passwordEmailModal, setPasswordEmailModal] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const navigate = useNavigate()
  const [tagOptions, setTagOptions] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const { duplicateUsers } = useSelector(state => state.tenantUsers)
  const [showTagModal, setShowTagModal] = useState(false)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [approveAffiliateModal, setApproveAffiliateModal] = useState(false);
  const [affiliateApproveLoading, setAffiliateApproveLoading] = useState(false);
  const [rejectAffiliateModal, setRejectAffiliateModal] = useState(false);
  const [affiliateRejectLoading, setAffiliateRejectLoading] = useState(false);

  const [dateRangePickerState, setDateRangePickerState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const handleClose = () => {
    setShowReasonModal(false)
    setEnable(false)
  }

  const handleVerifyEmail = () => {
    dispatch(verifyUserEmailStart({ isTenant: false, data: { userId: parseInt(userId) } }))
    setVerifyEmailModal(false)
  }

  const handleApproveAffiliate = async () => {
    setAffiliateApproveLoading(true);
    try {
      await approveAffiliate({ ...affiliateByIdData, role: 'Affiliate', commission: affiliateByIdData?.commision || 0 })
      toast("Afiliado aprovado com sucesso!", "success");
      setTimeout(() => {
        setApproveAffiliateModal(false);
        window.location.reload();
      }, 1000)
    } catch (err) {
      toast(err?.response?.data?.errors[0]?.description, "error");
    } finally {
      setAffiliateApproveLoading(false);
    }
  }

  const handleRejectAffiliate = async () => {
    setAffiliateRejectLoading(true);
    try {
      await rejectAffiliate({ affiliateId: affiliateByIdData.affiliateId })
      toast("Afiliado rejeitado com sucesso!", "success");
      setTimeout(() => {
        setRejectAffiliateModal(false);
        navigate(AdminsRoutes.AffiliatesManagement)
      }, 1000)
    } catch (err) {
      const message = resolveErrors(err.response.data?.errors, "Ocorreu um erro ao rejeitar afiliado");
      toast(message, "error");
    } finally {
      setAffiliateRejectLoading(false);
    }
  }

  const handleApproveSubAffiliate = async () => {
    setAffiliateApproveLoading(true);
    try {
      await approveSubAffiliate({ ...affiliateByIdData })
      toast("Afiliado aprovado com sucesso!", "success");
      setTimeout(() => {
        setApproveAffiliateModal(false);
        navigate(AffiliateRoutes.SubAffiliatesManagement)
      }, 1000)
    } catch (err) {
      toast(err?.response?.data?.errors[0]?.description, "error");
    } finally {
      setAffiliateApproveLoading(false);
    }
  }

  const fetchSABonus = () => {
    dispatch(getAllBonusStart({
      adminId: '',
      tenantId: '',
      limit: '',
      pageNo: '',
      search: '',
      bonusType: safeStringify(['deposit']),
      isActive: ''
    }))
  }

  useEffect(() => {
    let timer = null;
    if (bonusLoading) {
      timer = setTimeout(() => {
        setBonusLoading(false)
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [bonusLoading])

  // useEffect(() => {
  //   dispatch(getUserStart({ userId }))
  //   dispatch(getSAUserDocumentStart({ userId }))
  //   dispatch(getDocumentLabelStart({ userId }))
  //   fetchSABonus()
  // }, [userId])

  const handleYes = (reason, request, labelName) => {
    if (request === 'request' || request === 'cancel') {
      dispatch(updateDocumentStart({ data: { labelName, documentLabelId: userDocumentId, reason, userId: parseInt(userId), reRequested: true }, isRequested: (request === 'request') }))
    } else {
      dispatch(verifyUserDocumentStart({ userId, data: { userDocumentId, reason, status, userId: parseInt(userId) } }))
    }
    setShow(false);
    setShowReasonModal(false);
    setStatus('');
  }

  const handleVerify = (modalStatus, userDocumentId, myName) => {
    setStatus(modalStatus)
    setName(myName)
    setUserDocumentId(userDocumentId)
    if (modalStatus === 'approved') {
      setShow(true)
      setShowReasonModal(false)
    } else {
      setShow(false)
      setShowReasonModal(true)
    }
    setTitle('Rejecting')
  }

  useEffect(() => {
    if (!docLabels) {
      async function fetchData() {
        await getDocumentLabel('').then((res) => {
          setDocLabels(res?.data?.data?.documentLabel)
        })
      }
      fetchData()
    }
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', kycMethod: '' }))
  }, [])

  // useEffect(() => {
  //   dispatch(getAllBonusStart({ userId, bonusType: safeStringify(['match', 'balance', 'wagering']) }))
  // }, [showModal])

  useEffect(() => {
    if (!selectedBonus && bonusList?.length > 0) {
      setSelectedBonus(bonusList?.[0])
    }
    if (selectedBonus) {
      dispatch(getBonusStart({ userBonusId: '', bonusId: selectedBonus?.bonusId, isTenant: false }))
    }
  }, [bonusList, selectedBonus?.bonusId])

  const updateDocument = ({ documentLabelId, isRequested, labelName }) => {
    dispatch(updateDocumentStart({ data: { labelName, documentLabelId, userId: parseInt(userId) }, isRequested }))
  }

  const handleReRequest = (docId, myStatus, myName, myLabelName) => {
    setStatus('')
    setName(myName)
    setLabelName(myLabelName)
    if (myStatus === 'cancel') {
      setShow(true)
      setShowReasonModal(false)
    } else {
      setShow(false)
      setShowReasonModal(true)
    }
    setUserDocumentId(docId)
    setTitle('Re-Requesting')
  }

  const handleImagePreview = (documentUrl, name) => {
    setImageUrl({ name, preview: documentUrl })
    setImagePreviewModalShow(true)
  }

  const issueBonusHandler = () => {
    setShowModal(false)
    dispatch(issueBonusStart({ data: { userId: parseInt(userId), bonusId: selectedBonus?.bonusId, amount: bonusAmount } }))
    setBonusAmount('')
  }

  const showStyle = (data) => (data ? 'text-success' : 'text-danger');
  const printData = (data) => (data ? 'Sim' : 'Não');
  const printEnableDisable = (data) => (data ? 'Habilitado' : 'Desabilitado');

  const removeAffiliateHandler = () => {
    dispatch(updateUserAffiliateStart({ data: { userId, tenantId: userData?.tenantId, affiliateStatus: false }, isSuperAdmin: true, setShowRemoveAffiliate }))
  }

  const addAffiliateHandler = () => {
    if (userData?.trackingToken && userData?.isAffiliateUpdated === false) {
      dispatch(addAffiliateUserStart({ data: { userId, tenantId: userData?.tenantId, trackingToken: userData?.trackingToken }, isSuperAdmin: true, setShowAddAffiliate }))
    }
  }

  useEffect(() => {
    if (searchParams.get("tab")) setSelectedTab(searchParams.get("tab"))
  }, [searchParams.get("tab")])

  const basicInfo = [
    { label: 'ID', value: affiliateId },
    { label: 'Tipo de Afiliado', value: AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateByIdData?.affiliateType] },
    ...(affiliateByIdData?.streamerId ? [{ label: 'Id de Streamer', value: affiliateByIdData?.streamerId }] : []),
    { label: 'Email', value: affiliateByIdData?.email },
    { label: 'Nome completo', value: `${affiliateByIdData?.firstName} ${affiliateByIdData?.lastName}` },
    { label: 'Nome de usuário', value: affiliateByIdData?.username },
    { label: 'Telefone', value: affiliateByIdData?.phone },
    // { label: 'Gender', value: userData?.gender },
    // { label: 'Date Of Birth', value: formatDateYMD(userData?.dateOfBirth) },

    { label: 'Email verificado?', value: printData(affiliateByIdData?.isActive), subValue: showStyle(affiliateByIdData?.isActive) },
    { label: 'Status', value: affiliateByIdData?.status, subValue: showStyle(affiliateByIdData?.status) },
    // { label: 'In-Active Reason', value: userData?.defaultDisableReason || '-' },
    // { label: 'Portal', value: `${userData?.tenant?.name} (${userData?.tenant?.domain})` },
    // { label: 'Reason', value: !userData?.isActive ? userData?.reason : '' },
    // {
    //   label: 'Tags',
    //   value: userData?.tags
    //     ? userData?.tags?.length < 1 ? 'N/A' : userData?.tags?.join(', ')
    //     : 'N/A'
    // },
    // { label: 'SumSub Applicant Id', value: userData?.applicantId },
    // { label: 'KYC Method', value: userData?.kycMethod === 1 ? 'Sumsub' : 'System KYC' },
    // { label: 'KYC Status', value: userData?.kycMethod === 1 ? userData?.sumsubKycStatus?.toUpperCase() : userData?.kycStatus }
    { label: 'Sistema de Sub Afiliados', value: printEnableDisable(affiliateByIdData?.enableSubAffiliatesSystem), subValue: showStyle(affiliateByIdData?.enableSubAffiliatesSystem) },
  ]

  const kycInfo = [
    { label: 'Método', value: userData?.kycMethod === 1 ? 'Sumsub' : 'System KYC' },
    { label: 'Status', value: userData?.kycMethod === 1 ? userData?.sumsubKycStatus?.toUpperCase() : userData?.kycStatus }
  ];

  const contactInfo = [
    { label: 'Número de telefone', value: userData?.phone },
    { label: 'Endereço', value: userData?.address + ', ' + userData?.city + ', ' + userData?.zipCode },
    { label: 'Código do país', value: userData?.countryCode },
    { label: 'News letter', value: userData?.newsLetter ? 'Sim' : 'Não', subValue: showStyle(userData?.newsLetter) },
    { label: 'SMS', value: userData?.sms ? 'Sim' : 'Não', subValue: showStyle(userData?.sms) }
  ];

  const internalPlayerHandler = async () => {
    await dispatch(addUserInternalTagtStart({ data: { userId } }))
    setIsInternalModal(false)
    setTimeout(() => {
      dispatch(getUserStart({ userId }))
      dispatch(getSAUserDocumentStart({ userId }))
      dispatch(getDocumentLabelStart({ userId: parseInt(userId) }))
    }, 1000)
  }

  const updateUserStatus = (res) => {
    dispatch(updateSAUserStatusStart({
      data: {
        ...res,
        code: 'USER',
        userId,
        status: !userData.isActive,
        tenantId: userData.tenantId
      }
    }))
    setTimeout(() => {
      dispatch(getUserStart({ userId }))
      dispatch(getSAUserDocumentStart({ userId }))
      dispatch(getDocumentLabelStart({ userId: parseInt(userId) }))
    }, 1000)
  }

  const handleResetPasswordEmail = () => {
    dispatch(resetPasswordEmailStart({
      isTenant: false,
      data: { userId: +affiliateId }
    }))
    setPasswordEmailModal(false)
  }

  const handleResetUserPassword = (data) => {
    dispatch(resetUserPasswordStart({
      userType: 'Affiliate',
      isTenant: false,
      ...data
    }))
    setUserPasswordModal(false)
  }

  useEffect(() => {
    if (userData?.tags?.length > 0) {
      const options = []
      for (const i in userData?.tags) {
        if (userData?.tags[i] !== '' && userData?.tags[i] !== null) {
          options.push({ label: userData?.tags[i], value: userData?.tags[i] })
        }
      }
      setTagOptions(options)
    }
  }, [userData?.tags])

  useEffect(() => {
    const tag = []
    for (const i in userData?.tags) {
      userData?.tags[i] !== null && tag.push({ label: userData?.tags[i], value: userData?.tags[i] })
    }
    setSelectedTag(tag)
  }, [userData?.tags])

  const updateTags = (formValues) => {
    const tags = []
    let customTag = false
    for (const tag in formValues?.tags) {
      tags.push(formValues?.tags[tag]?.value)
      if (formValues?.tags[tag]?.isNew) {
        customTag = true
      }
    }
    dispatch(updateTagsStart({
      data: {
        tenantId: userData?.tenantId && JSON.parse(userData?.tenantId),
        userId: userId && JSON.parse(userId),
        parentId: userData.parentId,
        tags,
        customTag
      },
      isTenant: false
    }))
  }

  const addTag = () => {
    setShowTagModal(true)
  }

  const getDuplicateUsers = () => {
    setShowDuplicateModal(true)
  }

  // useEffect(() => {
  //   dispatch(getDuplicateUsersStart({
  //     limit,
  //     pageNo,
  //     userId,
  //     isTenant: false
  //   }))
  // }, [limit, pageNo])

  const updateAffiliateData = () => {
    dispatch(getAffiliateByIdStart({ affiliateId }))
  }

  useEffect(() => {
    dispatch(getAffiliateByIdStart({ affiliateId }))
    dispatch(getAffiliateDashboardDataFromAdminStart({ affiliateId })) // TODO: Pass type so that we can handel multiple api call based on same 
  }, [affiliateId])

  const handleDateRangeReset = () => {
    setDateRangePickerState([{
      startDate: null,
      endDate: null,
      key: 'selection'

    }])
  }

  useEffect(() => {
    let TYPE = 'yesterday';


    const paylod = {
      customStartDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      customEndDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      type: TYPE,
      affiliateId,
      reportOf: 'both'
    };
    dispatch(getAffiliateCommissionReportByIdStart(paylod));

    paylod.type = 'currentMonth';
    paylod.customStartDate = format(startOfMonth(new Date()), 'yyyy-MM-dd');
    paylod.customEndDate = format(new Date(), 'yyyy-MM-dd');

    dispatch(getAffiliateCommissionReportByIdStart(paylod));
    // dispatch(getSuperAdminGameSubCategoryStart({ limit:1000, pageNo: 1,gameCategoryId:'', isActive:true,  search:'' }))

  }, [])

  useEffect(() => {
    const payload = {
      customStartDate: dateRangePickerState[0]?.startDate ? formatDate(dateRangePickerState[0].startDate) : '',
      customEndDate: dateRangePickerState[0]?.endDate ? formatDate(dateRangePickerState[0].endDate) : '',
      type: 'totalReport',
      affiliateId,
      reportOf: 'casino'
    }
    dispatch(getAffiliateCommissionReportByIdStart(payload))
    dispatch(getAffiliateCommissionReportByIdStart({ ...payload, reportOf: 'sports' }))
  }, [dateRangePickerState])

  return {
    bonusList,
    selectedTab,
    userData: { ...userData, userId: Number(affiliateId) },
    loading,
    userDocuments,
    documentLabels,
    show,
    status,
    showReasonModal,
    title,
    imagePreviewModalShow,
    imageUrl,
    enable,
    docLabels,
    showModal,
    selectedBonus,
    bonusDetail,
    bonusAmount,
    basicInfo,
    kycInfo,
    contactInfo,
    freespinModal,
    showManageMoneyModal,
    showAddAffiliate,
    labelName,
    setShowAddAffiliate,
    setBonusAmount,
    issueBonusHandler,
    setSelectedBonus,
    setShowModal,
    setSelectedTab,
    handleVerify,
    updateDocument,
    setShow,
    handleYes,
    handleClose,
    setEnable,
    handleReRequest,
    setImagePreviewModalShow,
    handleImagePreview,
    setImageUrl,
    setShowManageMoneyModal,
    setFreeSpinModal,
    setIsInternalModal,
    isInternalModal,
    internalPlayerHandler,
    name,
    addUserAffiliateLoading,
    showRemoveAffiliate,
    updateUserAffiliateLoading,
    removeAffiliateHandler,
    setShowRemoveAffiliate,
    addAffiliateHandler,
    navigate,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    updateUserStatus,
    verifyEmailModal,
    setVerifyEmailModal,
    handleVerifyEmail,
    setPasswordEmailModal,
    setUserPasswordModal,
    userPasswordModal,
    passwordEmailModal,
    handleResetPasswordEmail,
    handleResetUserPassword,
    editUserModal,
    setEditUserModal,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    showTagModal,
    showDuplicateModal,
    setShowTagModal,
    setShowDuplicateModal,
    updateTags,
    tagOptions,
    setTagOptions,
    selectedTag,
    setSelectedTag,
    addTag,
    getDuplicateUsers,
    duplicateUsers,
    bonusLoading,
    setBonusLoading,
    affiliateByIdData,
    approveAffiliateModal,
    setApproveAffiliateModal,
    handleApproveAffiliate,
    handleApproveSubAffiliate,
    affiliateApproveLoading,
    dateRangePickerState,
    setDateRangePickerState,
    handleDateRangeReset,
    affiliateId,
    updateAffiliateData,
    rejectAffiliateModal,
    setRejectAffiliateModal,
    handleRejectAffiliate,
    affiliateRejectLoading,
  }
}

export default useAffiliateDetails
